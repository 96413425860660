<template>
  <div class="w-full bg-2 h-64 bg-2 rounded-t-lg">
    <div class="pt-6">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CardHeader",
  props: {
    background: {
      type: String,
      required: true
    }
  },
  computed: {
    backgroundUrl: {
      get() {
        return `url(${this.background})`
      }
    }
  }
}
</script>

<style scoped>
.bg-2 {
  background-image: linear-gradient(rgb(30 41 59 / 60%), rgb(30 41 59 / 100%)), v-bind('backgroundUrl');
  background-size: cover;
}
</style>