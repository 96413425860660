import { meta as D_58_47Code_322_47Code_47CheatCode_47Web3_47mint_45page_47pages_47index_46vueMeta } from "D:/Code 2/Code/CheatCode/Web3/mint-page/pages/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    file: "D:/Code 2/Code/CheatCode/Web3/mint-page/pages/index.vue",
    children: [],
    meta: D_58_47Code_322_47Code_47CheatCode_47Web3_47mint_45page_47pages_47index_46vueMeta,
    alias: D_58_47Code_322_47Code_47CheatCode_47Web3_47mint_45page_47pages_47index_46vueMeta?.alias || [],
    component: () => import("D:/Code 2/Code/CheatCode/Web3/mint-page/pages/index.vue").then(m => m.default || m)
  }
]